.homepage-container {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f7; /* Light grey background */
}

.homepage-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.homepage-description {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 20px;
}

.templates-section {
  margin-top: 7px;
  /* background-color: #ffffff; White background */
  padding: 24px;
  /* border-radius: 16px; Rounded corners */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow */
}

.category-card {
  transition: transform 0.2s;
  background-color: #ffffff; /* White background for the card */
  /* border-radius: 28px; Rounded corners */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow */
  /* border: 1px solid #e0e0e0; Light gray border */
}

.category-card:hover {
  transform: scale(1.05);
}

.category-title {
  font-weight: bold;
  font-size: 1.25rem;
  color: #333;
}

.card-actions {
  display: flex;
  justify-content: flex-end;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.pagination {
  margin-top: 20px;
}

.icon-button {
  color: #333; /* Change to fit your color scheme */
  background-color: #fff; /* White background */
  border: 1px solid #e0e0e0; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px;
  transition: transform 0.2s;
}

.icon-button:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

@media (max-width: 600px) {
  .homepage-title {
    font-size: 24px;
  }
  .category-card {
    margin: 10px 0;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
}
