/* Footer.css */

/* Footer Styles */
.footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  color: #6e6e73;
}

.footer a {
  color: var(--footer-link-color);
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}
