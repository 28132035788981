.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 732px; /* Adjust the max width as needed */
  margin: 0 auto;
}

.search-bar {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Adjust padding for space around text */
  border: 1px solid #ccc;
  border-radius: 25px; /* Rounded corners */
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
}

.search-bar:focus {
  border-color: #666; /* Change border color on focus */
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #666;
  pointer-events: none; /* Prevent click on the icon */
}
