/* Reset some basic styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #ececec;
  color: #1d1d1f;
  box-shadow: none;
  border-bottom: 1px solid #d2d2d7;
}

.navbar-logo {
  flex-grow: 1;
}

.navbar-brand {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #1d1d1f;
  text-decoration: none;
  padding: 20px 0;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  color: #1d1d1f;
  text-transform: none;
  margin-left: 20px;
  padding: 10px 20px;
  transition: background-color 0.2s;
}

.navbar-button:hover {
  background-color: #f0f0f5;
  border-radius: 8px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
  }

  .navbar-button {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
