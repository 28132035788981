/* App.css */

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* Wrapper for the entire page content */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex: 1;
}

/* Footer Styles */
.footer {
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
  color: #6e6e73;
}

.footer a {
  color: #0071e3;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}
